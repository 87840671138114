<template>
  <div>
    <template v-if="suffix === null || suffix === ''">
      <div
        class="text-view"
        :style="{
          'min-height': getSize(size),
        }"
      >
        {{ value || "" }}
      </div>
    </template>
    <template v-else>
      <div
        class="text-view mb-2"
        :style="{
          'min-height': getSize(size),
        }"
      >
        <div class="row">
          <div class="col-6">
            <span>{{ value || 0 }}</span>
          </div>
          <div class="col-6 text-right">
            <span v-show="suffix" class="font-weight-bold ml-2">
              {{ suffix }}
            </span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "",
    },
    suffix: {
      type: String,
      default: "",
    },
    value: {},
  },
  methods: {
    getSize(size) {
      if (size === "lg") {
        return "135px";
      } else if (size === "md") {
        return "90px";
      } else {
        return "40px";
      }
    },
  },
};
</script>
