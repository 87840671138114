<template>
  <div>
    <b-row align-v="center" class="w-100 mt-2">
      <b-col cols="1" class="p-1"></b-col>
      <b-col cols="9">
        <h6>{{ $t("breadcrumb.keyResultDescription") }}</h6>
        <br />
        <div class="mr-2">
          <slot name="description"> </slot>
        </div>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <hr v-show="$slots.footer"/>
    <b-row align-v="center" class="w-100 mt-2">
      <b-col cols="1" class="p-1"></b-col>
      <b-col cols="9">
        <div class="mr-2">
          <slot name="footer"></slot>
        </div>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow } from "bootstrap-vue";

export default {
  components: {
    BCol,
    BRow,
  },
};
</script>
