<template>
  <div>
    <load-profile></load-profile>
    <b-card>
      <validation-observer ref="createForm" #default="{ invalid }">
        <n-form-confirmation
          key="createForm"
          @submit="submit"
          :form="$refs.createForm"
          :disabled="invalid"
        >
          <n-input :fields="fields" v-model="data">
            <template #objectives>
              <b-tabs pills class="tab-card mt-2" v-model="tabIndex">
                <b-tab
                  v-for="(type, index) in objectiveTypes"
                  :key="`${type}-${index}`"
                  @click="getCurrentTab(type)"
                >
                  <template #title>
                    <span>
                      {{ getTranslate(type, "title", $i18n.locale) }}
                    </span>
                  </template>
                  <template>
                    <h5 class="my-2 mx-1" v-if="objectiveType.descriptionEn">
                      {{
                        getTranslate(objectiveType, "description", $i18n.locale)
                      }}
                    </h5>
                    <okr-setting-header class="mt-3" />
                    <app-collapse accordion type="margin">
                      <app-collapse-item
                        :is-visible="true"
                        v-for="(item, index) in objectives.filter(
                          (item) => item.objectiveTypeId === objectiveType.id
                        )"
                        :key="`${item}-${index}`"
                        :title="`${item}-${index}`"
                        class="mb-1"
                        :class="{
                          'bg-background': index % 2 != 0,
                        }"
                      >
                        <template #header>
                          <okr-setting-title :no="index + 1">
                            <template #description>
                              <p class="hint-text">
                                {{
                                  getTranslate(
                                    item,
                                    "description",
                                    $i18n.locale
                                  )
                                }}
                              </p>
                            </template>
                            <template #title>
                              <template v-if="item.titleEn == null">
                                <b-form-input
                                  v-model="item.title"
                                  autocomplete="off"
                                  :placeholder="$t('field.objective')"
                                ></b-form-input>
                              </template>
                              <template v-else>
                                <n-text-view
                                  :value="
                                    getTranslate(item, 'title', $i18n.locale)
                                  "
                                ></n-text-view>
                              </template>
                            </template>
                            <template #weight>
                              <b-input-group append="%">
                                <b-form-input
                                  ref="weight"
                                  type="number"
                                  :placeholder="$t('field.weight')"
                                  v-model="item.weight"
                                  autocomplete="off"
                                  maxLength="2"
                                ></b-form-input>
                              </b-input-group>
                            </template>
                          </okr-setting-title>
                        </template>
                        <hr />
                        <okr-setting-description>
                          <template #description>
                            <div
                              v-for="(result, idx) in item.objectiveKeyResults"
                              :key="`${result}-${idx}`"
                              class="mb-1"
                            >
                              <b-row>
                                <b-col cols="auto" class="pr-0">
                                  <label>#{{ idx + 1 }}.</label>
                                </b-col>
                                <b-col>
                                  <b-form-textarea
                                    class="pb-2"
                                    rows="2"
                                    max-rows="6"
                                    :placeholder="
                                      $t('field.objectiveKeyResult')
                                    "
                                    :value="result.title"
                                    v-model="result.title"
                                  ></b-form-textarea>
                                </b-col>
                              </b-row>
                            </div>
                          </template>
                        </okr-setting-description>
                      </app-collapse-item>
                    </app-collapse>
                    <okr-setting-footer
                      :total-weight="calculateWeight(objectiveType.id)"
                    />
                  </template>
                </b-tab>
              </b-tabs>
            </template>
          </n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <template v-if="tabIndex == objectiveTypes.length - 1">
                <n-button-save-option
                  ref="btnSubmit"
                  @submit="submit"
                  @save="save"
                  :loading="loading"
                  :resource="resource"
                  :route="route"
                  v-if="$can('create', resource)"
                ></n-button-save-option>
              </template>
              <n-button-loading
                type="button"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :loading="loading"
                class="mt-1"
                @submit="back"
              >
                {{ $t("button.back") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTabs,
  BTab,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BFormGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import FormInput from "./formInput";
import NButtonSaveOption from "@/components/NButtonSaveOption";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import OkrSettingHeader from "@/components/OkrSettingHeader.vue";
import OkrSettingTitle from "@/components/OkrSettingTitle.vue";
import OkrSettingFooter from "@/components/OkrSettingFooter.vue";
import OkrSettingDescription from "@/components/OkrSettingDescription.vue";
import NTextView from "@/components/NTextView.vue";
import { getTranslate } from "@/libs/helper";
import moment from "moment";
import Swal from "sweetalert2";
import state from "@/data/state";

const ObjectiveTypeRepository = Repository.get("objectiveType");
const ObjectiveRepository = Repository.get("objective");
const OkrSettingRepository = Repository.get("okrSetting");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTabs,
    BTab,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,

    NInput,
    NButtonLoading,
    NFormConfirmation,
    NButtonSaveOption,
    AppCollapse,
    AppCollapseItem,
    OkrSettingHeader,
    OkrSettingFooter,
    OkrSettingTitle,
    OkrSettingDescription,
    NTextView,
  },
  directives: {
    Ripple,
  },
  computed: {
    state() {
      return { ...state };
    },
  },
  data() {
    return {
      tabIndex: this.$route.query.tabIndex
        ? Number(this.$route.query.tabIndex)
        : 0,
      data: {
        employeeId: null,
        year: null,
        state: state.hrapproved,
        isEnable: true,
        objectives: [],
      },
      objectiveType: {},
      objectiveTypes: [],
      objectives: [],
      totalWeight: 0,
      loading: false,
      query: {
        page: Number(this.$route.query.page) || 1,
        order: "sequence_order",
        sort: "ASC",
      },
      objectiveQuery: {
        page: Number(this.$route.query.page) || 1,
        order: "id",
        sort: "ASC",
      },

      getTranslate,
    };
  },
  watch: {
    tabIndex(value) {
      this.$router.replace({
        query: {
          tabIndex: value,
        },
      });
    },
  },
  created() {
    var now = moment().format("Y");
    this.data.year = now;

    this.getObjectiveType();
    this.getObjective();
  },
  methods: {
    getCurrentTab(objectiveType) {
      this.objectiveType = objectiveType;
    },
    getObjectiveType() {
      this.loading = true;
      const params = {
        ...this.query,
      };
      ObjectiveTypeRepository.index(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.objectiveTypes = [...data.list];

            if (this.objectiveTypes.length > 0) {
              this.objectiveType = this.objectiveTypes[0];
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getObjective() {
      this.loading = true;
      const params = {
        ...this.objectiveQuery,
      };
      ObjectiveRepository.index(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.objectives = [...data.list];

            this.objectives.forEach((objective) => {
              if (objective.objectiveKeyResults.length <= 0) {
                const objectiveKeyResults = [
                  {
                    id: null,
                    title: null,
                  },
                  {
                    id: null,
                    title: null,
                  },
                  {
                    id: null,
                    title: null,
                  },
                  {
                    id: null,
                    title: null,
                  },
                  {
                    id: null,
                    title: null,
                  },
                ];
                objective.objectiveKeyResults.push(...objectiveKeyResults);
              }
            });
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    calculateWeight(objectiveTypeId) {
      var total = 0;
      this.objectives.forEach((item) => {
        if (item.objectiveTypeId == objectiveTypeId) {
          total += Number(item.weight);
        }
      });

      return total;
    },
    submit(type) {
      this.$refs.createForm.validate().then((success) => {
        if (success) {
          this.$refs.btnSubmit.confirm(type);
        }
      });
    },
    save(type) {
      this.loading = true;

      this.objectives.forEach((objective) => {
        objective.objectiveId = objective.id;

        if (!objective.title) {
          objective.title = objective.titleEn;
        }

        // Todo
        // objective.objectiveKeyResults = objective.objectiveKeyResults.filter(
        //   (keyResult) => keyResult.title !== null
        // );
      });
      this.data.objectives = [...this.objectives];

      OkrSettingRepository.create(this.data)
        .then((response) => {
          this.$refs.btnSubmit.afterSave(type, response.data.data.id);
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.createForm.setErrors(error.response?.data?.message);
            var message = error.response?.data?.message.objectives;

            Swal.fire({
              title: this.$t("error.invalid"),
              icon: "warning",
              html: message
                .map(
                  (message) =>
                    `<ul class='text-left'><li style='font-size: 14px'>${message}</li></ul>`
                )
                .join(""),
              customClass: {
                confirmButton: "btn btn-primary",
                title: "mb-2",
                content: "p-0",
              },
              buttonsStyling: false,
            });
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.push({
        name: `list-${this.route}`,
      });
    },
  },
  setup() {
    const fields = FormInput;
    const resource = "okr-setting";
    const route = "okr-setting";

    return { fields, resource, route };
  },
};
</script>
