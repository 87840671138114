<template>
  <b-card class="mt-1 card-head">
    <template #header>
      <b-row class="w-100">
        <b-col cols="7" md="10">
          <h6 class="text-white">{{ $t("field.totalWeight") }}</h6>
        </b-col>
        <b-col cols="5" md="2" class="text-right">
          <h4 class="text-white">{{ convertToDouble(totalWeight) }}%</h4>
        </b-col>
      </b-row>
    </template>
  </b-card>
</template>

<script>
import { BCol, BRow, BCard } from "bootstrap-vue";
import { convertToDouble } from "@/libs/helper";

export default {
  components: {
    BCol,
    BRow,
    BCard,
  },
  props: {
    totalWeight: {
      default: 0,
    },
  },
  data() {
    return {
      convertToDouble,
    };
  },
};
</script>
