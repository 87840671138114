export default [
  {
    key: "employeeId",
    label: "field.employee",
    rules: "required",
    type: "asyn-single-selection",
    repository: "employee",
    selectionKey: "id",
    selectionLabel: "nameLabel",
    query: { limit: 0, isEnable: true },
  },
  {
    key: "year",
    label: "field.year",
    rules: "required|integer|min:4|max:4",
    type: "year-picker",
  },
  {
    key: "isEnable",
    label: "field.active",
    rules: "",
    type: "checkbox",
    cols: "auto",
  },
  {
    label: "breadcrumb.okrSetting",
    icon: "ClipboardIcon",
    type: "divider",
  },
  {
    key: "objectives",
    hideLabel: true,
    cols: 12,
  },
];
