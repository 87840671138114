<template>
  <b-card class="m-0 card-head">
    <template #header>
      <b-row align-v="center" class="w-100">
        <b-col cols="1">
          <h6 class="text-white">{{ $t("field.no") }}</h6>
        </b-col>
        <b-col cols="9">
          <h6 class="text-left text-white">{{ $t("field.objective") }}</h6>
        </b-col>
        <b-col cols="2">
          <h6 class="text-center text-white">{{ $t("field.weight") }}</h6>
        </b-col>
      </b-row>
    </template>
  </b-card>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
} from "bootstrap-vue";

export default {
  components: {
    BCol,
    BRow,
    BCard,
  }
};
</script>
